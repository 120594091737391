import React from 'react'
import FormBoxLayout from 'src/components/FormBoxLayout'

function Account () {
  const title = 'Account'

  return <FormBoxLayout title={title}>{'Account page'}</FormBoxLayout>
}

export default Account
